import cn from 'classnames';
import dynamic from 'next/dynamic';
import { Fragment } from 'react';
import FlexList from '../../../../components/FlexList/FlexList';
import LoadingRing from '../../../../components/Loaders/LoadingRing/LoadingRing';
import { RetailsInfoContextValue, UnavailableListLabels } from '../../types';
import RetailsInfoUnavailableList from '../RetailsInfoUnavailableList/RetailsInfoUnavailableList';
import ResponsiveSkeleton from './ResponsiveSkeleton';
import getStyles from './RetailsInfo.styles';

const RetailInfoCard = dynamic(
  () => import('../RetailInfoCard/RetailInfoCard'),
  { ssr: false },
);

export interface CommonRetailsInfoProps {
  size: 'small' | 'medium' | 'large';
  onClickOpenAm(): void;
  onRetailInfoCardClick(isAvailable: boolean, chainId: string): void;
  unavailableListLabels: UnavailableListLabels;
}

export type RetailsInfoProps = RetailsInfoContextValue & CommonRetailsInfoProps;

const RetailsInfo = (props: RetailsInfoProps) => {
  const {
    size,
    onClickOpenAm,
    onRetailInfoCardClick,
    retailInfoLists,
    skeletonIsDisplayed,
    retailListsAreLoading,
    unavailableListLabels,
  } = props;
  const styles = getStyles();

  return (
    <div className={cn('RetailsInfo', {
      'RetailsInfo_isLoading': retailListsAreLoading,
    })}>
      {
        skeletonIsDisplayed ?
        <ResponsiveSkeleton /> :
        (
          <Fragment>
            <div className="RetailsInfo__wrapContent">
              <div
                className="RetailsInfo__list"
                data-marker='Available Retails'
              >
                <FlexList
                  size={size}
                  data={retailInfoLists.available}
                  renderItem={(retailInfo) => (
                    <RetailInfoCard
                      size={size}
                      retailInfo={retailInfo}
                      onClick={() => onRetailInfoCardClick(true, retailInfo.id)}
                      dataStatus='active'
                    />
                  )}
                />
              </div>

              {
                retailInfoLists.unavailable.length > 0 && (
                  <RetailsInfoUnavailableList
                    onClickOpenAm={onClickOpenAm}
                    onRetailInfoCardClick={onRetailInfoCardClick}
                    items={retailInfoLists.unavailable}
                    size={size}
                    unavailableListLabels={unavailableListLabels}
                  />
                )
              }
            </div>

            {
              retailListsAreLoading && (
                <div className="RetailsInfo__loader">
                  <LoadingRing color='#1EB763' size={'53'}/>
                </div>
              )
            }
          </Fragment>
        )
      }

      <style jsx>{styles}</style>
    </div>
  );
};

export default RetailsInfo;
